import React from "react"
import styled from "styled-components"
import {
  CellBoxShadow,
  colorWhite,
  colorGreyInput,
} from "../utilities/variables"
import { SubHeaderText, SubContentText } from "../elements"
import { window } from "browser-monads"

const Styledfaq = styled.div`
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${CellBoxShadow};
  border: 1px solid ${colorGreyInput};
  border-radius: 5px;
  max-width: 366px;
  width: 100%;
  display: grid;
  cursor: pointer;
  padding: 11px 13px;
  margin: 0;
  > * {
    margin: 0;
    text-align: start;
  }
`

export const FAQ = ({ type, title, url }) => {
  return (
    <Styledfaq onClick={() => (window.location.href = `faqs/${url}`)}>
      <SubContentText>{type}</SubContentText>
      <SubHeaderText>{title}</SubHeaderText>
    </Styledfaq>
  )
}
