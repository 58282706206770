import React, { useEffect, createRef } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import animation from "../assets/animations/home/toot-web-home-full-s-02.json"
import lottie from "lottie-web"
import { HeaderLargeText, SubHeaderText, HeaderText } from "../elements"
import GooglePlay from "../assets/images/icons/google-play-badge.png"
import AppStore from "../assets/images/icons/apple-store-badge.png"
import { useStaticQuery, graphql } from "gatsby"
import DynamicZone from "../components/dynamic-zone"

const TopSection = styled.section`
  .animation-container {
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  .text {
    top: 170px;
    width: 100%;
    margin-top: 26px;
    padding: 0 15px;
    z-index: 1;
    @media (min-width: 900px) {
      position: absolute;
      margin-top: 0;
      padding: 0;
    }
    > div {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      margin: auto;
      width: max-content;
      gap: 15px;
      margin-top: 30px;
    }
  }
  h2 {
    @media (min-width: 900px) {
      margin-bottom: 0;
    }
  }
  h3 {
    @media (min-width: 900px) {
      max-width: 830px;
      background-image: radial-gradient(
        51% 52%,
        hsla(0, 100%, 100%, 40%),
        hsla(0, 100%, 100%, 80%),
        transparent
      );
      padding: 30px 60px;
    }
  }
  h1,
  h3 {
    margin: auto;
  }
`

const IndexPage = () => {
  let animationContainer = createRef()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allStrapiHomepage {
        edges {
          node {
            AppStoreLink
            Description
            GooglePlayLink
            Subtitle
            Title
            Content {
              __typename
              id
              TextField
              ComponentType
              Title
              Description
              Image {
                id
                publicURL
              }
              # MediaItem {
              #   id
              #   publicURL
              #   ext
              # }
              blogs {
                id
                Title
                ShortDescription
                Slug
                Image {
                  id
                  publicURL
                }
              }
              AppStoreLink
              GooglePlayLink
              BulletPoints {
                id
                Title
                Description
              }
              us_ps {
                __typename
                id
                Title
                ShortDescription
                Image {
                  id
                  publicURL
                }
                created_at
              }
            }
          }
        }
      }
    }
  `)

  const content = data.allStrapiHomepage.edges[0].node

  return (
    <Layout>
      <SEO title="Home" />
      <TopSection>
        <div className="text">
          <HeaderLargeText>{content.Title}</HeaderLargeText>
          <HeaderText>{content.Subtitle}</HeaderText>
          <SubHeaderText
            dangerouslySetInnerHTML={{ __html: content.Description }}
          ></SubHeaderText>
          <div>
            <a href={content.AppStoreLink} target="_blank" rel="noreferrer">
              <img src={AppStore} alt="AppStore" />
            </a>
            <a href={content.GooglePlayLink} target="_blank" rel="noreferrer">
              <img src={GooglePlay} alt="GooglePlay" />
            </a>
          </div>
        </div>
        <div className="animation-container" ref={animationContainer}></div>
      </TopSection>
      <DynamicZone components={content.Content} />
    </Layout>
  )
}

export default IndexPage
