import React from "react"
import { HeaderText, ContentText, StyledMedia } from "../elements"
import {
  StyledJob,
  StyledTeam,
  StyledText,
  StyledGrid,
  StyledBoxedGrid,
  AppLayout,
} from "../layouts"
import styled from "styled-components"
import { FAQ } from "./faq"

const DynamicZone = ({ components }) => {
  return components.map((c, index) => {
    switch (c.ComponentType) {
      case "Title":
        return <HeaderText key={c.id}>{c.Title}</HeaderText>
      case "TextField":
        return (
          <>
            <ContentText
              sub
              padding
              key={c.id + c.Title}
              dangerouslySetInnerHTML={{ __html: c.TextField }}
            ></ContentText>
          </>
        )
      case "USPCell":
        return (
          <StyledGrid
            key={c.id + c.Title}
            title={components[index].Title}
            description={components[index].Description}
            items={c.us_ps}
          />
        )
      case "MediaItem":
        return (
          <StyledMedia
            key={c.id + components[index].Title}
            type={
              [".jpg", ".jpeg", ".png", ".gif"].includes(c.MediaItem.ext)
                ? `image`
                : `video`
            }
            source={c.MediaItem.publicURL}
          />
        )
      case "BlogGrid":
        return (
          <StyledBoxedGrid
            key={c.id + components[index].Title}
            title={components[index].Title}
            description={components[index].Description}
            items={c.blogs}
          />
        )
      case "TootAppHome":
        return (
          <AppLayout
            key={c.id + components[index].Title}
            title={components[index].Title}
            description={components[index].Description}
            image={components[index].Image.publicURL}
            items={c.BulletPoints}
            Google={components[index].GooglePlayLink}
            Apple={components[index].AppStoreLink}
          />
        )
      case "JobAdvert":
        return (
          <StyledWrapper count={components[index].jobs.length}>
            {components[index].jobs.map(job => {
              return (
                <StyledJob
                  key={c.id + components[index].Title}
                  title={job.Title}
                  description={job.Description}
                  link={job.slug}
                  field={job.PositionType}
                  location={job.Location}
                />
              )
            })}
          </StyledWrapper>
        )
      case "TeamMemberCell":
        return (
          <StyledTeam
            title={components[index].member.MemberName}
            jobtitle={components[index].member.JobTitle}
            description={components[index].member.MemberDescription}
            image={components[index].member.Image.publicURL}
            handle={components[index].member.LinkedIn}
          />
        )
      case "TeamMemberSmall":
        return (
          <StyledWrapper count={components[index].team_members.length}>
            {components[index].team_members.map(member => {
              return (
                <StyledTeam
                  small
                  title={member.MemberName}
                  jobtitle={member.JobTitle}
                  description={member.MemberDescription}
                  image={member.Image.publicURL}
                  handle={member.LinkedIn}
                />
              )
            })}
          </StyledWrapper>
        )
      case "FAQ":
        return (
          <StyledWrapper marginSmall faq count={components[index].faqs.length}>
            {components[index].faqs.map(faq => {
              return <FAQ type={faq.Type} title={faq.Title} url={faq.Slug} />
            })}
          </StyledWrapper>
        )
      case "TextCell":
        return (
          <StyledText
            title={components[index].Title}
            description={components[index].Description}
            image={components[index].Image.publicURL}
            cross={index % 2 ? true : false}
          />
        )
      default:
        return null
    }
  })
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  gap: 20px;
  justify-content: center;
  margin: ${props => (props.marginSmall ? `40px 0` : `90px 0`)};
  @media (min-width: 550px) {
    grid-template-columns: repeat(
      ${props => (props.count >= 2 ? 2 : 1)},
      max-content
    );
  }
  @media (min-width: 950px) {
    grid-template-columns: repeat(
      ${props => (props.count >= 2 ? (props.faq ? 3 : props.count) : 1)},
      max-content
    );
  }
`

export default DynamicZone
